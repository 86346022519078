.multibribes-button {
  width: 80px;
  height: 40px;
  background: linear-gradient(92.27deg, #32bbff 0%, #1f7099 100%);
  box-shadow: 0px 4px 12px rgba(0, 35, 57, 0.25);
  border-radius: 16px;
  border: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
