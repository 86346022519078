.multifarm-widget-infinite-table {
  color: #fff;
  overflow-x: auto;

  & * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 0.875rem;

    @media (max-width: 992px) {
      font-size: 0.75rem;
    }
  }

  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &-cell {
      font-weight: 600;
      padding: 0.75rem 0;
      position: relative;

      &[data-wrap='nowrap'] {
        white-space: nowrap;
      }
    }
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #303659;
    gap: 10px;
    border-radius: 1rem;
    margin-bottom: 10px;
  }

  &-cell {
    display: flex;
    align-items: center;
    height: 50px;
    flex-wrap: wrap;
    word-wrap: break-word;

    &[data-wrap='nowrap'] {
      white-space: nowrap;
    }

    &-color {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 9999px;
    }
  }
}
