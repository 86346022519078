.page-overview-statistics {
  width: 100%;
  display: flex;
  flex-flow: row;
  column-gap: 20px;
  flex-wrap: wrap;
  flex: 2;

  @media (max-width: 400px) {
    flex-flow: column;
  }

  .multibribes-card {
    flex: 15%;

    @media (max-width: 900px) {
      flex: 25%;
    }

    @media (max-width: 600px) {
      flex: 35%;
    }
  }
}
