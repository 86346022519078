.page-bribe-markets-gagues-overview {
  &__content {
    max-width: 100%;
    overflow-x: overlay;
  }

  & .sort-wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &-header {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;

    .filter-dropdown {
      display: flex;
      gap: 8px;
      &-title {
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 10px;

        svg {
          color: var(--accent-color);
        }
      }
    }

    .voters {
      &-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }

      &-pill {
        border: 1px solid var(--accent-color);
        color: var(--accent-color);
        padding: 8px 16px;

        &-active {
          background: var(--accent-color);
          color: var(--main-bg);
        }
      }
    }

    .search {
      &-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        gap: 10px;

        svg {
          color: var(--accent-color);
        }
      }
    }
  }

  .gauge-card {
    .multifarm-widget-card-title {
      font-size: 30px;
      font-weight: 400;
    }
  }

  .remaining-rewards {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
  }

  .apr-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
  }
}
