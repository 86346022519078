.page-platform-details-details-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 20px;

  .multibribes-details-card {
    flex: 20%;
  }

  .multibribes-card {
    height: 100%;
    margin: 0;
  }

  .flex-20 {
    flex: 20%;
  }

  .flex-60 {
    flex: 60%;
  }
}
