:root {
  --main-bg: #08090f;
  --card-bg: rgb(24 26 33 / 90%);

  --text-primary: #ffffff;
  --text-highlighted: #416bff;

  --grid-stroke: #416bff;

  --accent-color: #416bff;
}
