.page-bribe-markets {
  .select-token {
    &-container {
      display: flex;
      gap: 8px;
    }
    &-dropdown {
      width: 160px;
    }
    &-dropdown-prompt {
      display: flex;
      white-space: nowrap;
      align-items: center;
      column-gap: 10px;

      svg {
        color: var(--accent-color);
      }
    }
  }
}
