.page-bribe-markets-governance-power-token-holdings-chart {
  .token-holding-header-component {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;

    .token-select {
      width: 150px !important;
    }
  }
}
