@import url('./colors.scss');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Tooltip styling
  .multifarm-widget-info-tooltip {
    background-color: var(--main-bg) !important;
    border: 1px solid var(--accent-color) !important;
    border-radius: 0;
  }
}
