.multibribes {
  color: var(--text-primary);
  position: relative;
  overflow: hidden;

  a {
    color: var(--text-highlighted);
    font-weight: 700;
  }

  .clickable {
    cursor: pointer;
  }

  .multifarm-heading {
    color: rgba(255, 255, 255, 0.7);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  &-content {
    z-index: 2;
    min-height: 100vh;
  }

  &-page {
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;

    &-container {
      display: flex;
      flex-flow: row;
    }
  }

  // Card styling
  &-card {
    border-radius: 0 !important;
    background: var(--card-bg) !important;

    .multifarm-widget-card-header-container {
      text-transform: uppercase;
    }

    &-transparent {
      background: none !important;

      .multibribes-card {
        background: none !important;
      }
    }
  }

  // List styling
  &-list {
    li {
      background: var(--card-bg);
    }
  }

  // Select stylings
  .multifarm-widget-select {
    background: var(--main-bg);
    border: 1px solid var(--accent-color);
    border-radius: 0;
  }

  .multifarm-widget-select-container {
    svg {
      color: var(--accent-color);
    }
  }

  // Chart stylings
  .recharts-area-curve {
    stroke: var(--accent-color);
  }

  // Grid stylings
  .borderless-grid {
    .recharts-wrapper
      .recharts-cartesian-grid-horizontal
      line:nth-last-child(2),
    .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
      stroke-opacity: 0;
    }
  }

  // Tabs stylings
  .multifarm-widget-tabs {
    background: var(--card-bg);
    border-radius: 16px;
    text-transform: none;
  }

  .multifarm-widget-tabs-tab[data-active='true'] {
    background: var(--accent-color);
    box-shadow: 0px 4px 12px rgba(0, 35, 57, 0.25);
    border-radius: 0;
  }

  // Table styling
  .multifarm-widget-table {
    border-spacing: 0;
    tr {
      &:nth-child(odd) {
        .multifarm-widget-table-cell {
          background: rgba(255, 255, 255, 0.05);
        }
      }

      &:nth-child(even) {
        .multifarm-widget-table-cell {
          background: rgba(255, 255, 255, 0.1);
        }
      }

      .multifarm-widget-table-cell {
        background: var(--card-bg);
        &:first-child {
          border-radius: 0;
        }

        &:last-child {
          border-radius: 0;
        }
      }
    }
  }

  // Infinite table styling
  .multifarm-widget-infinite-table {
    min-width: 600px;

    &-header {
      background: rgba(65, 107, 255, 0.16);
    }

    &-row {
      background: var(--card-bg);
      border-radius: 0;
      margin-bottom: 0;

      &:nth-child(odd) {
        background: rgba(255, 255, 255, 0.05);
      }

      &:nth-child(even) {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  .multifarm-infinite-loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  // Text styling
  .highlight {
    color: var(--text-highlighted);
  }
}
