.asset-details-card {
  &-content {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    overflow-x: auto;

    .multibribes-details-card {
      flex: 30% !important;
    }
  }
}
