.menu-tab {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 10px;
  min-width: 200px;
  padding: 8px 16px;
  cursor: pointer;
  color: #ffffff50;

  @media (max-width: 900px) {
    padding: 16px 8px;
  }

  .icon-container {
    min-height: 40px;
    min-width: 40px;
    background-color: #ffffff17;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-container-active {
    background-color: var(--accent-color);
  }
}
