.footer {
  width: 100%;
  height: 100px;
}

.disclaimer {
  width: 100%;
  text-align: center;

  p {
    padding: 0 20px;
  }
}

.address {
  font-weight: 700;
}
