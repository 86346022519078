.multibribes-details-card {
  border-radius: 16px;

  &-container {
    display: flex;
    flex-flow: column;
    gap: 20px;
  }

  &-links {
    display: flex;
    flex-flow: column;
    gap: 10px;
  }
}
