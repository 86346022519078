.page-bribe-markets-governance-power {
  .gauge-select {
    width: 200px !important;

    @media (max-width: 600px) {
      width: 100% !important;
    }
  }

  .governance-power-pie {
    .multifarm-widget-table {
      tr {
        .multifarm-widget-table-cell {
          background: none !important;
        }
      }
    }
  }
}
