.page-header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 20px;

  &-logo {
    height: 36px;

    @media (max-width: 900px) {
      height: 30px;
    }
  }

  &-left-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    column-gap: 30px;
    height: 100%;
    overflow: hidden;
  }

  &-right-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    column-gap: 20px;
    height: 100%;
  }
}
