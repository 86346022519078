.page-platform-details {
  .platform-select {
    width: 200px !important;

    @media (max-width: 600px) {
      width: 100% !important;
    }
  }

  &-charts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    grid-template-areas:
      'a a a a'
      'b b c c';

    @media (max-width: 900px) {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .page-platform-details-vote-rewards-chart {
      grid-area: a;

      @media (max-width: 900px) {
        width: 100%;
      }
    }

    .page-platform-total-bribes-over-time-chart {
      grid-area: b;
      @media (max-width: 900px) {
        width: 100%;
      }
    }

    .page-platform-governance-power-chart {
      grid-area: c;
      @media (max-width: 900px) {
        width: 100%;
      }
    }
  }
}
