.page-tabs {
  display: flex;
  align-items: center;
  column-gap: 30px;
  width: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;

  .active {
    position: relative;
    color: white;

    &:after {
      position: absolute;
      content: '';
      border-bottom: 2.5px solid var(--accent-color);
      width: 100%;
      transform: translateX(-50%);
      bottom: -1.5px;
      left: 50%;
    }
  }
}
