.page-platform-details-overview {
  &-lists {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    grid-template-areas: 'a b';

    @media (max-width: 900px) {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .left-list {
      grid-area: a;
      @media (max-width: 900px) {
        width: 100%;
      }
    }

    .right-list {
      grid-area: b;
      @media (max-width: 900px) {
        width: 100%;
      }
    }

    .multifarm-widget-info-list-item-title {
      font-weight: 700;
    }
  }
}
