.menu-toggle {
  cursor: pointer;
  &-open &-line-top {
    transform: translate3d(0px, 8px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }

  &-open &-line-bottom {
    transform: translate3d(0px, -8px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }

  &-open &-line-middle {
    width: 0;
  }

  &-line-bottom,
  &-line-top,
  &-line-middle {
    width: 24px;
    height: 2px;
    padding-right: 0px;
    padding-bottom: 0px;
    background: white;
  }

  &-line-top,
  &-line-bottom {
    transition: transform 200ms linear;
  }

  &-line-middle {
    margin-top: 6px;
    margin-bottom: 6px;
    transition: width 200ms linear;
  }
}
