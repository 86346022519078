.page-bribe-markets-governance-power-details-cards {
  margin-bottom: 20px;
  .other-details-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 10px;

    .multibribes-card {
      height: 100%;
    }

    .multibribes-details-card {
      flex: 30%;
    }
  }
}
