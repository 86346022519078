.page-bribe-markets-gagues {
  .page-tabs {
    .active {
      &:after {
        border-bottom: 4px solid #d733ff;
      }
    }

    .page-tab {
      text-transform: uppercase;
    }
  }
}
