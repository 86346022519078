.menu-tabs {
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 20px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .active {
    position: relative;
    color: white;

    &:after {
      position: absolute;
      content: '';
      border-top: 1.5px solid var(--accent-color);
      width: 40px;
      transform: translateX(-50%);
      top: 0;
      left: 36px;
    }
  }
}
