.votex-sort-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  line-height: 0;
  cursor: pointer;

  &:hover, &__active {
    color: lightblue;
  }

  &__rotated {
    transform: rotate(180deg);
  }
}
