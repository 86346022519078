.liquid-wrapper {
  display: flex !important;
  flex-flow: row !important;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    flex-flow: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .wrapper-name {
    font-weight: 700;
  }

  &-left-container {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }

  &-right-container {
    display: flex;
    flex-flow: column;
    gap: 40px;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      gap: 10px;
    }

    .stat {
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      gap: 10px;
      width: 100%;

      @media (max-width: 600px) {
        justify-content: flex-start;
      }
    }

    .main-stat {
      font-weight: 700;
    }

    .other-stats {
      display: flex;
      flex-flow: column;
      gap: 10px;
      align-items: flex-end;
      justify-content: center;
      width: 100%;

      @media (max-width: 600px) {
        justify-content: flex-start;
      }
    }
  }
}
