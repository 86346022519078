.multibribes-menu {
  padding: 10px 0;
  transition: width 0.2s linear;
  height: 100%;

  @media (max-width: 900px) {
    position: fixed;
    top: 80px;
    z-index: 999;
    background: var(--main-bg);
  }

  &-closed {
    width: 0px;
    .menu-tabs {
      opacity: 0;
      transition: opacity 0.1s linear;
    }
  }

  &-open {
    width: 250px;

    @media (max-width: 900px) {
      width: 190px;
    }

    .menu-tabs {
      opacity: 1;
    }
  }
}
