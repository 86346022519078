.page-header-tab {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 10px;
  padding: 8px 16px;
  text-transform: uppercase;

  &-active {
    position: relative;
    color: white;

    &:after {
      position: absolute;
      content: '';
      border-top: 1.5px solid var(--accent-color);
      width: 40px;
      transform: translateX(-50%);
      top: 0;
      left: 36px;
    }
  }

  .icon-container {
    height: 40px;
    width: 40px;
    background-color: #ffffff17;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-container-active {
    background-color: var(--accent-color);
  }
}
