.multibribes-input {
  display: flex;
  align-items: center;
  gap: 16px;

  &-field {
    outline: none;
    padding: 8px;
    border: 1px solid var(--accent-color);
    background: transparent;
    color: white;
  }
}
