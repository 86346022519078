.background-container-dissaray {
  position: absolute;
  width: 100vw;
  z-index: -1;
  min-height: 100vh;
  height: 100%;
  background: var(--main-bg);

  &__graphics {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
